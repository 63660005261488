import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./NoticeAndNews.css";
import { Mousewheel, Autoplay } from "swiper/modules";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ourpopnews } from "../../../../utils/apis/common/Common";
import { Context } from "../../../../utils/context";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ShimmerThumbnail } from "react-shimmer-effects";



function Banner() {
    const [isLoading, setIsLoading] = useState(true);
    const news = [
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
    ];

    const notice = [
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
            images: process.env.PUBLIC_URL + "/assets/images/logo/bharti-logo.png",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },

    ];


    const popnewslider = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        cssEase: "linear"
    };


    const { IMG_URL } = useContext(Context);

    const [popnews, setPopnews] = useState([]);

    const getDataAll = async () => {

        const res = await ourpopnews();
        console.log(res, "nesw");

        if (res?.success) {
            setPopnews(res?.data);
        }
    };

    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            {isLoading ? (
                <ShimmerThumbnail height={600} rounded />
            ) : (
                <section className="Notice-news">
                    <Container>
                        <div className="title-profcyma-help text-center py-5">
                            {/* <h5>Top News And Notice</h5> */}
                            <h2 className="title">Our Popular News And Notice</h2>
                        </div>
                        <Row className="ms-0 me-0">

                            <Col lg={6}>
                                <img className="newss-imggg" src={process.env.PUBLIC_URL + '/assets/images/latest-news/news.jpg'} />
                            </Col>

                            <Col lg={6}>
                                <div className="slider-container">
                                    <Slider {...popnewslider}>
                                        {popnews?.map((data, index) => (
                                            <div>
                                                <a href={data?.link} target="_blank" rel="noopener noreferrer">
                                                    <div className="newsslider-div row">
                                                        <div className="newimg-div col-1">
                                                            <img src={IMG_URL + data?.image} className="spec" alt="Notice" />
                                                        </div>
                                                        <div className="newcont col-10">
                                                            <p className="sub-text">{data?.name}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            )}
        </>

    );
}

export default Banner;
