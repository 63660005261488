import React from 'react'
import { Helmet } from 'react-helmet'

const SpecialiZationMeta = () => {
  return (
      <Helmet>
          <meta name="Online MBA in Human Resource Management | Human Resources Courses | Profcyma Career Solutions
                " content="Advance your career with an online MBA in HR, offering comprehensive human resources courses and degrees with profcyma career solutions
                " />

          <meta name="online programme in marketing management india | MBA Degree In Marketing | Profcyma Career Solutions
                " content=" Earn your MBA in Marketing Management online! Profcyma connects you with top-ranked universities in India for flexible online learning.
                " />

          <meta name="Top Online Financial Management Courses | Financial Management for Managers | Profcyma
                " content="Find the best online financial management courses to fit your needs. Learn from experts, gain practical skills, and advance your career.
                " />

          <meta name="Online MBA in Information Technology | IT Management Degrees | Profcyma
                " content=" Elevate your tech skills with an MBA in Information Technology. Learn IT leadership, strategy & management. Explore online programs!
                " />

          <meta name="Online MBA in Operations Management | Degree,Certificate Courses | Profcyma 
                " content=" Gain the skills to optimize operations & lead teams. Earn your online MBA in Operations Management - flexible learning, real-world application.
                " />

          <meta name="Online Healthcare Management Courses | Hospital Administration Courses | Profcyma
                " content="Upskill in healthcare management from anywhere in India! Explore online courses, build your knowledge & advance your career.
                " />

          <meta name="Online MBA in International Business | International Business Degrees: MBAs, Masters | Profcyma
                " content="Balance work & career advancement with an MBA in International Business Management. Accredited program, distance learning format.
                " />

          <meta name="Unlock Your Future with an MBA in Fintech Online |  financial technology | Profcyma
                " content="Discover the top MBA in Fintech programs online. Gain expertise in financial technology and enhance your career prospects with flexible courses.
                " />

          <meta name="Top Online MBA in Business Analytics Programs | 
                " content="Explore the best online MBA in Business Analytics courses in India. Learn details, find affordable programs, and advance your career with flexible learning
                " />

          <meta name="Master Business Analytics with an Online MBA | Flexible & Comprehensive Online Program | Profcyma
                " content="Pursue an online MBA in Business Analytics. Master data analytics and business insights with a flexible and comprehensive online program. Start your journey now! 
                " />

          <meta name="Online MBA in AI & Machine Learning | artificial intelligence courses | Profcyma
                " content="Join our online MBA in Artificial Intelligence and Machine Learning. Discover top AI courses and boost your career with advanced skills in tech.
                " />


          <meta name="Online MBA in Logistics & Supply Chain Management | Flexible & Top-rated
                " content="Earn an online MBA in Logistics and Supply Chain Management. Explore top-rated programs and enhance your career with flexible learning options.
                " />

          <meta name="Online MBA in Agribusiness Management | Flexible & Career-Focused | Profcyma
                    " content="Advance your career with an online MBA in Agribusiness Management. Discover flexible and career-focused programs tailored for the agribusiness sector.
                    " />

          <meta name="Online MBA in Digital Marketing | Master Your Marketing Skills | Profcyma
                " content="Enroll in an online MBA in Digital Marketing. Master essential marketing skills and advance your career with flexible and comprehensive courses.
                " />
      </Helmet>
  )
}

export default SpecialiZationMeta