import React from 'react'
import { Helmet } from 'react-helmet';

const MetaAbout = () => {
    return (
        <>
            <Helmet>
                <meta name="India's leading provider of distance and online education | Online MBA Programmes | Profcyma Career Solutions" content= "We are proud to be recognized as India's premier provider for admission to distance/online education, specializing in distance MBA programs." />
            </Helmet>
        </>
    )
}

export default MetaAbout