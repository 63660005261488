import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../../../utils/context";
import './SpecializationSyllabus.css';
import { Accordion, Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import parse from 'html-react-parser';
import { ShimmerThumbnail } from 'react-shimmer-effects';

const SpecializationSyllabus = ({ id }) => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const getDataAll = async () => {
            try {
                const response = await getData(`/without-login/ed-tech/specialization/syllabus/${id}`);
                const fetchedData = response?.data || [];
                setData(fetchedData);
                if (fetchedData.length > 0) {
                    setActiveTab(fetchedData[0]?.id);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const timer = setTimeout(() => {
            getDataAll();
        }, 3000);

        return () => clearTimeout(timer);
    }, [getData, id]);

    const handleSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <>
            {loading && <ShimmerThumbnail height={400} rounded />}
            {!loading && data && data.length > 0 &&
                <section className='Specialization-Syllabus'>
                    <Container>
                        <Row>
                            <div className='heading-holder text-center mb-4'>
                                <h2>Syllabus</h2>
                                <div className='border-line'></div>
                            </div>
                        </Row>
                        {data.length > 0 && (
                            <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleSelect}>
                                <Row>
                                    <Col xxl={3} xl={3} lg={4} md={4} className='px-md-0 px-sm-none px-none'>
                                        <Nav variant="pills" className="flex-column">
                                            {data.map((item, index) => (
                                                <Nav.Item key={index} className='mb-3'>
                                                    <Nav.Link eventKey={item?.id}>{item.name}</Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    </Col>
                                    <Col xxl={9} xl={9} lg={8} md={8} className='px-md-0 px-sm-none px-none'>
                                        <Tab.Content>
                                            {data.map((item, index) => (
                                                <Tab.Pane eventKey={item?.id} key={index}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Subject's</Accordion.Header>
                                                            <div className='border-header'></div>
                                                            <Accordion.Body>
                                                                <div className='subject-holder'>
                                                                    {parse(item?.description) || ""}
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        )}
                    </Container>
                </section>
            }
        </>
    );
};

export default SpecializationSyllabus;
