import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import './BlogsBanner.css'
import ImageContext from '../../../Utils/MyContext/MyContext';
import { ShimmerThumbnail } from "react-shimmer-effects";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

function BlogsBanner() {
    const { name } = useContext(ImageContext);
    const { getData, IMG_URL } = useContext(Context);
    const [blogData, setBlogData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDataAll = async () => {
        const response = await getData(`/without-login/ed-tech/blog/blog-banner`);
        setBlogData(response?.data);
        setIsLoading(false);
    };

    useEffect(() => {
        const loadBannerData = () => {
            getDataAll();
        };
        const timer = setTimeout(loadBannerData, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <section className='BlogBanner-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 desktop-view-banner'>
                            <div className='blogbanner-image'>
                                <Swiper
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {isLoading ? (
                                        <ShimmerThumbnail height={380} rounded />
                                    ) : (
                                        blogData?.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <div className='image-div'>
                                                    <img src={IMG_URL + image?.image} className='blog-img' />
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    )}
                                </Swiper>
                            </div>
                        </div>
                        <div className='col-md-12 mobile-view-banner'>
                            <div className='blogbanner-image'>
                                <Swiper
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {isLoading ? (
                                        <ShimmerThumbnail height={380} rounded />
                                    ) : (
                                        blogData?.map((data, index) => (
                                            <SwiperSlide key={index}>
                                                <div className='image-div'>
                                                    <img src={IMG_URL + data?.image} className='banner-img1' />
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogsBanner;
