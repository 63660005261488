import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../../utils/context";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "./registrationprocess.css";
import parse from "html-react-parser";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ShimmerSectionHeader } from "react-shimmer-effects";
const RegistrationProcess = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [showShimmer, setShowShimmer] = useState(true);

  const getDataAll = async () => {
    const response = await getData(
      `/without-login/ed-tech/admission/admission-process`
    );
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);



  useEffect(() => {
    const delay = setTimeout(() => {
      setShowShimmer(false);
    }, 3000);

    getDataAll();

    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      <section className="registration-process">
        <Container>
          {showShimmer &&
            <div className="mt-5">
              <ShimmerSectionHeader center />
            </div>
          }
          {!showShimmer && (
            <div className="text-holder-process">
              <h2 className="title mb-4">Registration Process</h2>
              <p>
                Candidates may apply for admission online, gain information regarding admission procedures of different universities, call our counsellors, or chat with us.
              </p>
              <p>
                The respective students are required to submit the admission form duly filled up and self-attested along with all the mandatory documents and the applicable fees required for processing the admission process. The mandatory documents and online fee receipt can be submitted online by email to the university or can be couriered.
              </p>
            </div>
          )}
          <div className="row process-swiper">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Autoplay]}
              className=""
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 20 },
                360: { slidesPerView: 1, spaceBetween: 20 },
                425: { slidesPerView: 2, spaceBetween: 20 },
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                992: { slidesPerView: 3, spaceBetween: 30 },
                1200: { slidesPerView: 3, spaceBetween: 40 },
                1400: { slidesPerView: 4, spaceBetween: 40 },
                1800: { slidesPerView: 4, spaceBetween: 40 },
                2500: { slidesPerView: 4, spaceBetween: 40 },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
                loop: true,
              }}
            >
              {data?.map((process, index) => (
                <SwiperSlide key={index}  >
                  <Card className="main-card-process">
                    <Card.Body>
                      <Card.Text>
                        <p className="card-text">{parse(process?.description) || ""}</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </section>
    </>
  );
};

export default RegistrationProcess;
