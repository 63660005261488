import React, { useContext, useState, useEffect } from "react";
import './MbaDetails.css';
import { Link } from 'react-router-dom';
import { Context } from "../../../../utils/context";
import { popularcource } from "../../../../utils/apis/common/Common";
import { latestpost } from "../../../../utils/apis/common/Common";
import { ShimmerContentBlock, ShimmerCategoryItem } from "react-shimmer-effects";

function MbaDetails({ blogData }) {

    // const baseURL = "/ed-tech";
    const { getData, IMG_URL } = useContext(Context);

    const [latestData, setLatestData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLatepost, setIsLatepost] = useState(true);
    const [isPopcourse, setIsPopcourse] = useState(true);

    const getDataAllLatest = async () => {
        const response = await getData(`/without-login/ed-tech/blog/latest-blogs`);
        setLatestData(response?.data);
    };

    useEffect(() => {
        getDataAllLatest();
        console.log(latestData);
    }, []);

    const [popcourse, setPopcourse] = useState([]);
    const getPopularCource = async () => {
        const res = await popularcource();
        console.log(res, "nesw");
        if (res?.success) {
            setPopcourse(res?.data);
        }
    };

    useEffect(() => {
        getPopularCource();
    }, []);

    const [latepost, setLatepost] = useState([]);
    const getLatestPost = async () => {
        const res = await latestpost();
        console.log(res, "getLatestPost");
        if (res?.success) {
            setLatepost(res?.data?.recent_post);
        }
    };

    useEffect(() => {
        getLatestPost();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);


    // latepost

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLatepost(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    // Popcourse

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPopcourse(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    const coursedata = [
        {
            id: 1,
            title: "Difference Between Online MBA And Offline MBA",
            btname: "Read More",
            imagepath: process.env.PUBLIC_URL + "/assets/images/Blogs/girlboy.png",
            pathlinks: "/blog/difference"
        },
        {
            id: 2,
            title: "Online MBA Myth & Facts",
            btname: "Read More",
            imagepath: process.env.PUBLIC_URL + "/assets/images/Blogs/laptopboy.png",
            pathlinks: "/blog/online-mba-myth"
        },
        {
            id: 3,
            title: "Online Degree Value",
            btname: "Read More",
            imagepath: process.env.PUBLIC_URL + "/assets/images/Blogs/laptopimage.png",
            pathlinks: "/blog/online-degree-value"
        }
    ];

    const scrolldata = [
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/template2.png",
            content1: "Introduction LearnPress – LMS plugin",
            content2: "Free"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/template.png",
            content1: "Create an LMS Website with LearnPress",
            content2: "Free"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady3.png",
            content1: "How To Teach Online Courses Effectively",
            content2: "Free"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady3.png",
            content1: "How To Teach Online Courses Effectively",
            content2: "Free"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady3.png",
            content1: "How To Teach Online Courses Effectively",
            content2: "Free"
        },
    ];

    const scrolldata2 = [
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady2.png",
            content1: "Introducing: Dr. Deniz Zeynep",
            content2: "20 Jan 2024",
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady.png",
            content1: "LMS WordPress plugin",
            content2: "20 Jan 2024"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady3.png",
            content1: "LMS WordPress plugin",
            content2: "20 Jan 2024"
        },
        {
            image: process.env.PUBLIC_URL + "/assets/images/Blogs/lady3.png",
            content1: "Admin earns scholarship",
            content2: "20 Jan 2024"
        },
    ];

    return (
        <>
            <section className='mbadetails-section'>
                <div className='container'>
                    <div className='mbadetails-main'>
                        <div className='row'>
                            <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'>
                                <div className='leftsection'>
                                    <div className='row'>
                                        {isLoading ? (
                                            blogData?.map(() => (
                                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-5'>
                                                    <ShimmerContentBlock
                                                        text
                                                        cta
                                                        thumbnailWidth={130}
                                                        thumbnailHeight={100}
                                                    />
                                                </div>
                                            ))
                                        ) : (
                                            blogData?.map((contents) => (
                                                <div key={contents.id} className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                                    <Link to={`/blog/online-mba-myth/${contents?.id}`}>
                                                        <div className='blog-main-card'>
                                                            <div className='row'>
                                                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                                                                    <div className='cont-div'>
                                                                        <div className='heading-main'>
                                                                            <h5 className='Heading-cards title'>{contents.name}</h5>
                                                                        </div>
                                                                        <div className='readmoreclass'>
                                                                            <Link to={`/blog/online-mba-myth/${contents?.id}`}>
                                                                                <button className='readMorebutton title'>Read More</button>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                                                                    <div className='img-div'>
                                                                        <img className='imagemain' src={IMG_URL + contents?.heading_image} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'>
                                <div className='rightside'>
                                    <div className='row'>
                                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12'>
                                            <div className='scoll-1'>
                                                <div className='heads'>
                                                    <h5>POPULAR COURSES</h5>
                                                </div>
                                                <div className='scrollbar-div'>
                                                    <div className='row'>
                                                        {isLatepost ? (
                                                            popcourse?.map((contents2) => (
                                                                <ShimmerCategoryItem
                                                                    hasImage
                                                                    imageType="thumbnail"
                                                                    imageWidth={60}
                                                                    imageHeight={70}
                                                                    title
                                                                />
                                                            ))
                                                        ) : (
                                                            popcourse?.map((contents2) => (
                                                                <div key={contents2.id} className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                    <Link
                                                                        // to={`/ed-tech/courses/${contents2?.id}`}
                                                                        to={`/courses/${contents2?.id}/${contents2.name.replace(/\s+/g, '-').toLowerCase()}`}
                                                                    >
                                                                        <div className='row mb-2'>
                                                                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                                                <div className='scroll-imagediv'>
                                                                                    <img className='scroll-image' src={IMG_URL + contents2?.image} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9'>
                                                                                <div className='scroll-cont'>
                                                                                    <div className='scroll-top-con'>
                                                                                        <p className='top-p'>{contents2?.name}</p>
                                                                                        <p className='scroll-bottom-con scroll-p2'>{contents2?.description}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12'>
                                            <div className='scoll-1'>
                                                <div className='heads'>
                                                    <h5>LATEST POSTS</h5>
                                                </div>
                                                <div className='scrollbar-div'>
                                                    <div className='row'>
                                                        {isPopcourse ? (
                                                            latepost?.map((contents2, index) => (
                                                                <ShimmerCategoryItem
                                                                    hasImage
                                                                    imageType="thumbnail"
                                                                    imageWidth={60}
                                                                    imageHeight={70}
                                                                    title
                                                                />
                                                            ))
                                                        ) : (latepost?.map((contents2, index) => (
                                                            <div key={contents2.id} className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                <Link to={`/blog/online-mba-myth/${contents2?.id}`}>
                                                                    <div className='row mb-2'>
                                                                        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                                            <div className='scroll-imagediv'>
                                                                                <img className='scroll-image' src={IMG_URL + contents2?.heading_image} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9'>
                                                                            <div className='scroll-cont'>
                                                                                <div className='scroll-top-con'>
                                                                                    <p className='top-p'>{contents2?.name}</p>
                                                                                    <p className='scroll-bottom-con scroll-p'>{contents2?.createdAt}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MbaDetails;
