import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";
import "./drdybanner.css";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import InquiryFormModal from "../../../InquiryFormModal/InquiryFormModal";
import { ShimmerThumbnail } from "react-shimmer-effects";

const Drdybanner = ({ id, universityName }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/university/common/small-banner/${id}`);
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
      getDataAll();
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [id]);

  return (
    <>
      {loading && <ShimmerThumbnail height={250} rounded />}
      {!loading && data && (
        <section className='drdypatilbanner-section'>
          <div className='container'>
            <div className="banner-div desktop-view-banner">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="banner-img-main" onClick={() => setModalShow(true)}>
                    <img
                      src={IMG_URL + data?.image}
                      className="banner-img"
                      alt={data?.alt}
                    />
                  </div>
                </SwiperSlide>
                <InquiryFormModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  setModalShow={setModalShow}
                  modalShow={modalShow}
                />
              </Swiper>
            </div>
            <div className="banner-div mobile-view-banner">
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="banner-img-main">
                    <img
                      src={IMG_URL + data?.image}
                      className="banner-img"
                      alt={data?.alt}
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Drdybanner;
