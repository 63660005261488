import React from 'react'
import { Helmet } from 'react-helmet';

const Metahome = () => {
    return (
        <>
            <Helmet>
                <meta name="Best Online MBA for working professionals. | Top Online MBA Colleges in India for Distance Education |Profcyma Career Solutions" content="Explore the best online MBA programs in India at top-ranked universities. Elevate your career with quality education and flexible learning options." />
            </Helmet>
        </>
    )
}

export default Metahome