import React from 'react'
import OnlineLearning from './online-learning/OnlineLearning'
import Homebanner from './home-banner/Homebanner'
import Whoweare from './who-we-are/Whoweare'
import Universityslider from './university-partner/Universityslider'
import Media from './Media/Media'
import Faq from './faq/Faq'
import VideoProfcyma from './Video-profcyma/VideoProfcyma'
import ProfcymaHelp from './profcymahelp/ProfcymaHelp'
import TopCources from './topcources/TopCources'
import AluminiHub from './alumini-hub/AluminiHub'
import ProfcymaEdge from './profcymaedge/ProfcymaEdge'
import Counter from './counter/Counter'
import Right_guidance from './right-guidance/Right_guidance'
import OurAluminis from './OurAluminis/OurAluminis'
import NoticeAndNews from './NoticeAndNews/NoticeAndNews'
import Metahome from './metatag-home/Metahome'


const Home = () => {
  return (
    <>
      <Metahome />
      <Homebanner />
      <Whoweare />
      <Universityslider />
      <Counter />
      <ProfcymaHelp />
      <TopCources />
      <AluminiHub />
      <ProfcymaEdge />
      <OnlineLearning />
      <Media />
      <Right_guidance />
      <OurAluminis />
      <NoticeAndNews />
      <VideoProfcyma />
      <Faq />

    </>
  )
}

export default Home