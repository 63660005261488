import React, { useContext, useEffect, useState } from 'react'
import { Context } from "../../../../utils/context";
import './MasteringManagement.css'
import { Col, Container, Row } from 'react-bootstrap'
import parse from 'html-react-parser'
import { ShimmerSectionHeader, ShimmerText, ShimmerThumbnail } from 'react-shimmer-effects';

const MasteringManagement = ({ id }) => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        const response = await getData(`/without-login/ed-tech/specialization/with-online/${id}`);
        setData(response?.data[0]);
        setLoading(false); // Stop loading once data is fetched
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            getDataAll();
        }, 3000); // 3 seconds delay

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [id]);

    return (
        <>
            {loading &&
                <Container className='mt-5'>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={6}>
                            <ShimmerSectionHeader />
                            <ShimmerText line={5} gap={10} />
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={6}>
                            <ShimmerThumbnail height={250} rounded />
                        </Col>
                    </Row>
                </Container>
            }
            {!loading && data && (
                <section className='master-management'>
                    <Container>
                        <div className='card'>
                            <Row>
                                <Col lg={6}>
                                    <div className='management-holder'>
                                        <div className='main-heading'>
                                            <h2>{data?.name}</h2>
                                            {typeof data?.description === 'string' ? parse(data?.description) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='management-img'>
                                        <img className="girl-img"
                                            src={
                                                IMG_URL + data?.image
                                            } alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            )}
        </>
    )
}

export default MasteringManagement;
