import React from 'react'
import AboutBanner from './about-banner/AboutBanner';
import AboutText from './about-text/AboutText';
import AboutAdvantage from './about-advantage/AboutAdvantage';
import MetaAbout from './metatag-about/MetaAbout';


const AboutUs = () => {
  return (
    <>
      <MetaAbout />
      <AboutBanner />
      <AboutText />
      <AboutAdvantage />
    </>
  )
}

export default AboutUs;