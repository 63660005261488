import React, { useState, useEffect } from 'react';
import './Myths.css';
import { Container } from 'react-bootstrap';
import parse from "html-react-parser";
import { ShimmerPostDetails } from "react-shimmer-effects";

function Myths(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading && <ShimmerPostDetails card cta variant="SIMPLE" />}
            {!loading && (
                <section className='myths-section p-3'>
                    <Container>
                        <div className='myths-main'>
                            {parse(props.blogData.details)}

                            {/* <div className='myths-part left-side'>
                            <div className='myth-heading  left-heading '>
                                <h3>Myth 1</h3>
                            </div>
                            <div className='myth-contents left-contents text-start'>
                                <h3 className='title'>Online MBA programs are not as challenging as on-campus programs.</h3>
                                <p>Contrary to popular belief, online MBA programs offer a level of rigor
                                    comparable to traditional on-campus counterparts. Reputable business
                                    schools provide both online and offline programs concurrently, ensuring
                                    consistency in curriculum, faculty, and study materials. Online students
                                    face similar challenges and learning opportunities,
                                    debunking the misconception that online programs are less demanding.</p>
                            </div>
                        </div>


                        <div className='myths-part right-side'>
                            <div className='myth-heading right-heading'>
                                <h3>Myth 2</h3>
                            </div>
                            <div className='myth-contents right-contents text-end'>
                                <h3 className='title'>To pursue an online MBA, you must be technologically proficient.</h3>
                                <p>There exists a misconception that only tech-savvy individuals can excel
                                    in online learning. The reality is that anyone with a basic understanding
                                    of internet usage can enroll in an online MBA course. The process is as
                                    simple as surfing the internet or using an app. For those less familiar
                                    with technology, universities often offer assistance and introductory sessions,
                                    ensuring a smooth transition into the online learning environment.</p>
                            </div>
                        </div>

                        <div className='myths-part left-side'>
                            <div className='myth-heading  left-heading '>
                                <h3>Myth 3</h3>
                            </div>
                            <div className='myth-contents left-contents text-start'>
                                <h3 className='title'>There isn't any direct communication with faculty members.</h3>
                                <p>A prevalent myth suggests that online MBA students lack meaningful interactions
                                    with professors and peers. In reality, online students have various avenues for
                                    communication, including doubt-solving sessions,
                                    email, and discussion forums. While the level of interaction may vary between
                                    institutions, many online programs prioritize fostering connections between students
                                    and faculty, creating a supportive learning environment.</p>
                            </div>
                        </div>


                        <div className='myths-part right-side'>
                            <div className='myth-heading right-heading'>
                                <h3>Myth 4</h3>
                            </div>
                            <div className='myth-contents right-contents text-end'>
                                <h3 className='title'>Programs for online MBAs do not require the participation of a learner.</h3>
                                <p>Another misconception is that online MBA programs involve minimal effort from students.
                                    On the contrary, active participation is crucial for success. Students engage in video
                                    lectures, participate in activities, adhere to project timelines, and take assessments
                                    to demonstrate their understanding of the material. Earning an MBA degree online requires
                                    dedication and effort, dispelling the notion that success can be achieved merely by enrolling.</p>
                            </div>
                        </div>

                        <div className='myths-part left-side'>
                            <div className='myth-heading  left-heading '>
                                <h3>Myth 5</h3>
                            </div>
                            <div className='myth-contents left-contents text-start'>
                                <h3 className='title'>Online MBA programs are not offered by reputable business schools.</h3>
                                <p>Some believe that only traditional universities provide reputable MBA programs. However, prestigious
                                    institutions globally, such as Imperial College London, The University of Warwick, The University of
                                    Edinburgh, Cranfield School of Management, and Lancaster University, offer online MBA degrees. These
                                    programs adhere to the same high standards as their on-campus counterparts, providing a quality education
                                    to students worldwide.</p>
                            </div>
                        </div> */}
                        </div>
                    </Container>
                </section>
            )}
        </>
    );
}

export default Myths;
