import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Context } from "../../../../utils/context";
import "./ebrochure.css";
import { Container, Button } from "react-bootstrap";
import GetotpModal from "./GetotpModal/GetotpModal";
import SubitFormModal from "./SubitFormModal/SubitFormModal";
import classNames from 'classnames';
import { ShimmerThumbnail } from "react-shimmer-effects";

const Ebrochure = () => {
  const { postData, getData, Select2Data } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({});

  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState("");
  const [modalSubmitedShow, setModalSubmitedShow] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [course, setCourse] = useState([]);
  const [otp, setOtp] = useState([]);
  const [otpError, setOtpError] = useState([]);
  const [state, setState] = useState([]);
  const [seconds, setSeconds] = useState(90);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [verifyStat, setVerifyStat] = useState(true);
  const [mobileVerify, setMobileVerify] = useState(false);

  const sendOTP = async () => {
    try {
      const response = await postData("/without-login/ed-tech/verify-otp/otp-send", {
        contact_no: getValues("contact_no"),
      });
      setShowOtpInput(true);
      setOtpError("");
      setMessage("OTP Sent Successfully")
      console.log("OTP sent successfully:", response);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const getOTP = async () => {
    try {
      const response = await postData("/without-login/ed-tech/verify-otp/verify-otp", {
        contact_no: getValues("contact_no"),
        otp: getValues("otp"),
      });
      if (response?.success) {
        setShowOtpInput(false);
        setModalShow(true);
        setMessage("OTP Verified Successfully")
        setSeconds(90);
        setMobileVerify(true);
        setOtp("");
        setOtpError("");
        clearErrors("contact_no");
      } else {
        setOtpError(response?.message);
        setMobileVerify(false);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showShimmer, setShowShimmer] = useState(true);

  const onSubmit = async (data) => {
    if (!mobileVerify) {
      setError("contact_no", { type: "manual", message: "Verify Contact No. First" });
    } else {
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("email", data?.email);
      finalData.append("contact_no", data?.contact_no);
      finalData.append("course_id", data?.course_id?.value);
      finalData.append("course_type_id", data?.course_type_id?.value);
      finalData.append("state_id", data?.state_id?.value);
      const response = await postData("/without-login/ed-tech/customer-management/e-brochure", finalData);
      if (response.success) {
        clearErrors();
        setModalSubmitedShow(true);
        reset();
        setTimeout(() => {
          setModalSubmitedShow(false);
          window.scrollTo(0, 0);
          // setMobileOTP(true);
        }, 3000);
        setMobileVerify(false);
      } else {
        console.log("response", response);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalShow(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [modalShow]);

  const GetAllQualification = async () => {
    const response = await getData("/without-login/ed-tech/master/all-course-types");
    if (response.success) {
      setQualifications(await Select2Data(response?.data, "course_type_id"));
    }
  };

  const GetAllCourse = async (id) => {
    const response = await getData(`/without-login/ed-tech/master/all-courses/${id}`);
    if (response.success) {
      setCourse(await Select2Data(response?.data, "course_id"));
    }
  };

  const GetAllState = async () => {
    const response = await getData("/without-login/ed-tech/master/all-admission-state");
    if (response.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };



  useEffect(() => {
    GetAllState();
    GetAllQualification();
    GetAllCourse();
  }, []);

  const handleClickGetOTP = () => {
    const contactNumber = getValues("contact_no");
    const numberOfDigits = contactNumber.length;
    if (contactNumber === "") {
      setShowMessage(true);
    } else if (numberOfDigits !== 10) {
      setShowErrorMessage(true);
      setShowMessage(false);
    } else {
      sendOTP();
      setSeconds(90);
      // setMobileOTP(false);
      setShowErrorMessage(false);
      setShowMessage(false);
      setModalShow(true);
      setShowOtpInput(false);
    }
  };

  useEffect(() => {
    const decrementTimer = () => {
      if (seconds > 0) {
        setIsTimerExpired(false);
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  // Custom styles for react-select components
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 'calc(1.5em + 0.75rem + 2px)',
      minHeight: '38px',
      borderColor: state.isFocused ? '#80bdff' : '#ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
      '&:hover': {
        borderColor: '#80bdff'
      }
    }),
    menu: base => ({
      ...base,
      zIndex: 9999
    }),
    valueContainer: base => ({
      ...base,
      padding: '0.375rem 0.75rem'
    }),
    placeholder: base => ({
      ...base,
      color: '#6c757d'
    })
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setShowShimmer(false);
    }, 3000);
    return () => clearTimeout(delay);
  }, []);


  return (
    <>
      {showShimmer &&
        <div className="mt-5">
          <ShimmerThumbnail height={400} rounded />;
        </div>
      }
      {!showShimmer && (
        <section className="e-brochure">
          <div className="text-holder-brochure">
            <h2 className="title mb-3">E-Brochure</h2>
            <p>To download the brochure please fill out the form below</p>
          </div>
          <Container fluid className="p-0">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/admission/brochure-banner.png"
              }
              className="brochure-banner-img"
              alt="..."
            />
          </Container>
          <div className="enquiry-form">
            <div className="apply-online-title">
              <h2>
                <span>Apply Online</span>
              </h2>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>

              <Form.Group controlId="formGridname" className="mb-3">
                <Form.Control
                  name="name"
                  className={classNames("", {
                    "is-invalid": errors?.name,
                  })}
                  type="text"
                  {...register("name", {
                    required: "Name is required",
                    pattern: {
                      value: /^[A-Za-z ]*$/,
                      message: "Name must contain only letters",
                    },
                  })}
                  onKeyDown={(event) => {
                    if (!/[A-Z-a-z ]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Full Name"
                />
                {/* {errors?.name && <div className="invalid-feedback">{errors.name.message}</div>} */}
              </Form.Group>

              <Form.Group controlId="formGridname" className="mb-3">
                <Form.Control
                  className={classNames("", { "is-invalid": errors?.email })}
                  type="email"
                  {...register("email", {
                    required: "E-mail is required",
                    pattern: {
                      value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                      message: "Invalid E-Mail Address",
                    },
                  })}
                  placeholder="E-Mail"
                  onChange={() => clearErrors("email")}
                />
                {/* {errors?.email && (
                <span className="text text-danger">
                  {errors.email.message}
                </span>
              )} */}
              </Form.Group>
              <div>
                <div className="black-border-input getotop-main">
                  <Form.Group controlId="formGridname" className="mb-2" >
                    <Form.Control
                      className={classNames("", { "is-invalid": errors?.contact_no })}
                      disabled={mobileVerify}
                      type="tel"
                      {...register("contact_no", {
                        required: "Contact number is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Contact number must be a 10-digit number",
                        },
                      })}
                      onKeyDown={(event) => {
                        const key = event.key;
                        if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Contact Number"
                      maxLength={10}
                      onChange={() => clearErrors("contact_no")}
                    />
                    {!mobileVerify &&
                      <button
                        className="get-otp-btn"
                        type="button"
                        onClick={handleClickGetOTP}
                      >
                        Get OTP
                      </button>
                    }
                  </Form.Group>
                </div>

                {errors?.contact_no && (
                  <span className="text text-danger">
                    {errors.contact_no.message}
                  </span>
                )}
              </div>

              {showOtpInput && (
                <div className="black-border-input getotop-main">
                  <Form.Group controlId="formGridname" className="mb-3">
                    <Form.Control
                      className={classNames("", { "is-invalid": errors?.otp })}
                      type="tel"
                      {...register("otp", {
                        required: "Please Enter OTP First",
                        pattern: {
                          value: /^\d{4}$/,
                          message: "OTP must be a 4-digit number",
                        },
                      })}
                      onKeyDown={(event) => {
                        const key = event.key;
                        if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="OTP"
                      maxLength={4}
                    />
                    <button
                      className="get-otp-btn"
                      type="button"
                      onClick={() => {
                        if (getValues("otp") === '') {
                          setError("otp", { type: "manual", message: "Enter OTP First" })
                          return;
                        }
                        getOTP();
                      }}
                    >
                      Verify OTP
                    </button>
                  </Form.Group>
                  {errors?.otp && (
                    <span className="text text-danger">
                      {errors.otp.message}
                    </span>
                  )}
                </div>
              )}

              {showOtpInput && (
                <p>Enter OTP within{" "}
                  {`${Math.floor(seconds / 60)}:${(seconds % 60)
                    .toString()
                    .padStart(2, "0")}`}{" "}
                  seconds</p>
              )}
              {otpError && <p className="otp text text-danger">{otpError}</p>}
              {isTimerExpired && (
                <p className="error-msg">
                  Didn't get the OTP? <span onClick={handleClickGetOTP} style={{ cursor: 'pointer', color: '#fff' }}> Click to Resend.</span>
                </p>
              )}

              <div>
                <Controller
                  name="state_id"
                  className={classNames("", { "is-invalid": errors?.state_id })}
                  {...register("state_id", {
                    required: "State is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="State"
                      styles={customStyles}
                      {...field}
                      options={state}
                    />
                  )}
                />
              </div>
              {errors?.state_id && (
                <span className="text text-danger">
                  {errors.state_id.message}
                </span>
              )}

              <div className="mt-2">
                <Controller
                  name="course_type_id"
                  className={classNames("", { "is-invalid": errors?.course_type_id })}
                  {...register("course_type_id", {
                    required: "Qualification is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="Qualification"
                      styles={customStyles}
                      {...field}
                      options={qualifications}
                      onChange={(selectedOption) => {
                        GetAllCourse(selectedOption.value);
                        setValue("course_type_id", selectedOption);
                        setValue("course_id", "")
                      }}
                    />
                  )}
                />
                {errors?.course_type_id && (
                  <span className="text text-danger">
                    {errors.course_type_id.message}
                  </span>
                )}
              </div>
              <div className="mt-2">

                <Controller
                  name="course_id"
                  className={classNames("", { "is-invalid": errors?.course_id })}
                  {...register("course_id", {
                    required: "Course is required",
                  })}
                  control={control}

                  render={({ field }) => (
                    <Select
                      placeholder="Course"
                      styles={customStyles}
                      {...field}
                      options={course}
                    />
                  )}
                />
                {errors?.course_id && (
                  <span className="text text-danger">
                    {errors.course_id.message}
                  </span>
                )}
              </div>
              <div className="mt-2 text-lg-start text-md-center text-center">
                <button
                  type="submit"
                  className="submit-btn-enquiry-form"
                >
                  Submit Enquiry
                </button>
              </div>
            </Form>
          </div>
        </section>
      )}
      <GetotpModal
        show={modalShow}
        message={message}
        onHide={() =>
          setModalShow(false)}
      />
      <SubitFormModal show={modalSubmitedShow} onHide={() => setModalSubmitedShow(false)} />
    </>
  );
};

export default Ebrochure;