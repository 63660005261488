import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../utils/context';
import './SpecializationLeadership.css';
import { Col, Container, Row } from 'react-bootstrap';
import parse from 'html-react-parser';
import { ShimmerContentBlock } from 'react-shimmer-effects';

const SpecializationLeadership = ({ id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataAll = async () => {
    const response = await getData(`/without-login/ed-tech/specialization/why-choose/${id}`);
    await setData(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);

  }, []);

  useEffect(() => {
    getDataAll();
  }, [id]);

  return (
    <>
      <section className='leardership-specialization'>
        <div className='container-lg'>
          {loading ? (
            <Row>
              {data?.map((card, index) => (
                <Col lg={6} md={6}>
                  <ShimmerContentBlock
                    title
                    text
                    cta
                    thumbnailWidth={370}
                    thumbnailHeight={370}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Row>
              {data?.map((card, index) => (
                <Col key={index} lg={6} md={6}>
                  <div data-aos="zoom-in" >
                    <div className='first-box mb-md-3 mb-sm-3 mb-3'>
                      <div className='image-holder'>
                        <img className="student-img" src={IMG_URL + card.image} alt="" />
                      </div>
                      <div className='card-holder ' >
                        <h2>{card.name}</h2>
                        <p>{parse(card.description)}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </section>
    </>
  );
};

export default SpecializationLeadership;
