import React from 'react'
import { Helmet } from 'react-helmet'

const CommonUniversity = () => {
    return (
        <Helmet>
            <meta name="Explore DY Patil Universitys Distance Education | Online MBA | Profcyma Career Solutions" content="Advance your career with DY Patil University Distance Education. Discover flexible online MBA options with Profcyma Career Solutions." />

            <meta name="Explore Amity University's Online MBA Programmes | Distance MBA | Profcyma Career Solutions" content="Advance your career with Amity's online MBA. Flexible schedules, top faculty, and recognized degrees. Enroll now with Profcyma Career Solutions" />

            <meta name="Bharati Vidyapeeth Distance Education MBA | Online MBA Programs | Profcyma" content="Explore Bharati Vidyapeeth Distance Education MBA programs. Enroll in Bharati Vidyapeeth Online MBA to advance your career with flexible, accredited courses designed for working professionals. " />

            <meta name="Explore Chandigarh University's Distance Education Programmes | Online MBA | Profcyma Career Solutions" content="Discover Chandigarh University's Distance Education Programs with Profcyma Career Solutions. Enroll in an Online MBA to elevate your career with flexible and accredited courses tailored for professionals." />

            <meta name="Symbiosis Centre for Distance Learning | Online MBA | Profcyma Career Solution" content="Join Symbiosis Centre for Distance Learning to advance your career with a range of courses, including Symbiosis Distance Learning MBA and Online MBA. Flexible, accredited programs designed for working professionals." />
        </Helmet>
    )
}

export default CommonUniversity